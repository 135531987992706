import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleExclamation,
	faCircleCheck,
	faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

const Message = styled.div`
	align-items: center;
	color: ${(props) =>
		props.success
			? props.theme.colors.gold
			: props.invalid
			? props.theme.colors.red_darker
			: props.theme.colors.black};
	display: flex;
	font-size: 16px;
	margin: 5px 0;
	> svg:first-child {
		margin-right: 5px;
	}
`;

function ErrorMessage(props) {
	const { message, success, invalid, info } = props;

	return (
		<>
			{message && (success || invalid || info) && (
				<Message {...props}>
					<FontAwesomeIcon
						className="InputBtn"
						icon={
							success
								? faCircleCheck
								: invalid
								? faCircleExclamation
								: faCircleInfo
						}
					/>{' '}
					{message}
				</Message>
			)}
		</>
	);
}

export default ErrorMessage;
