import React, { Component } from 'react';
import styled from 'styled-components';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import * as ZapparThree from '@zappar/zappar-threejs';

import TWEEN from '@tweenjs/tween.js';

import answer_a from '../../assets/answer_a.png';
import answer_b from '../../assets/answer_b.png';
import answer_c from '../../assets/answer_c.png';
import answer_d from '../../assets/answer_d.png';

import dots from '../../assets/dots.png';

import box_top from '../../assets/box_top.png';
import box_bottom from '../../assets/box_bottom.png';
import box_side from '../../assets/box_side.png';
import box_up from '../../assets/box_up.png';

// import level1 from '../../assets/rui/level1.glb';
// import level2 from '../../assets/rui/level2.glb';
// import level3 from '../../assets/rui/level3.glb';

const board = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/board.glb';
const level1 = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/garrafas.glb';
const level2 = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/copos.glb';
const level3 = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/canecas.glb';

const environment = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/env.hdr';
const fontType = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/Roboto.font';

const targetImage = 'https://zappar-media.s3.eu-west-1.amazonaws.com/saber-autentico/caixa.zpt';

const Root = styled.div`
    background-color: #333;
	position: fixed;
	z-index: 0;
	top: 0;
`;

class Game extends Component {

    constructor(props) {
        super(props);

        this.load = this.load.bind(this);
        this.setup = this.setup.bind(this);
        this.update = this.update.bind(this);
        this.onReady = this.onReady.bind(this);
        this.loadFont = this.loadFont.bind(this);
        this.loadLevel = this.loadLevel.bind(this);
        this.loadBoard = this.loadBoard.bind(this);
        this.showLevel = this.showLevel.bind(this);
        this.changeLevel = this.changeLevel.bind(this);
        this.onFontLoaded = this.onFontLoaded.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    componentDidMount() {
        if (this.renderer) return;

        this.levels = [level1, level2, level3];

        this.renderer = new THREE.WebGLRenderer({
            outputEncoding: THREE.SRGBColorSpace,
            antialias: true,
            alpha: true
        });

        this.renderer.physicallyCorrectLights = true;
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        this.pmremGenerator.compileEquirectangularShader();

        this.renderer.setSize(window.innerWidth, window.innerHeight);

        window.addEventListener('resize', () => {
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            if (this.camera && this.camera.type == "PerspectiveCamera") {
                this.camera.aspect = window.innerWidth / window.innerHeight;
                this.camera.updateProjectionMatrix();
            }
        });

        this.mount.appendChild(this.renderer.domElement);

        this.scene = new THREE.Scene();

        this.update(0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.section !== this.props.state.section) {
            const { section, level } = this.props.state;
            switch (section) {
                case 'loading':
                    this.load();
                    break;
                case 'clear':
                    this.clear();
                    if (this.level != level) {
                        this.changeLevel(level, this.nextQuestion);
                    }
                    break;
                case 'marker':
                    this.setup();
                    break;
                case 'start':
                    this.start();
                    break;
                case 'quiz':
                    this.nextQuestion();
                    break;
            }
        }
    }

    load() {
        if (this.manager) return;

        this.manager = new ZapparThree.LoadingManager();

        this.getCubeMapTexture(environment).then(({ envMap }) => {
            this.envMap = envMap;
            this.scene.environment = envMap;
        });

        this.loadBoard();
    }

    setup() {
        if (this.game) return;

        this.game = new THREE.Group();

        let geometry, map, material;

        if (this.props.state.box) {
            this.camera = new ZapparThree.Camera();
            this.camera.start();

            ZapparThree.glContextSet(this.renderer.getContext());

            this.scene.background = this.camera.backgroundTexture;

            geometry = new THREE.PlaneGeometry(130, 184, 3, 3);
            map = new THREE.TextureLoader().load(dots);
            material = new THREE.MeshBasicMaterial({ map, transparent: true });

            this.floor = new THREE.Mesh(geometry, material);
            this.floor.rotateX(-Math.PI / 2);

            this.game.add(this.floor);

            const boxTracker = new ZapparThree.ImageTrackerLoader().load(targetImage);

            this.scene.visible = false;
            this.trackerGroup = new ZapparThree.ImageAnchorGroup(this.camera, boxTracker);

            boxTracker.onVisible.bind(() => {
                this.scene.visible = true;
                this.onMarker(true);
            });

            boxTracker.onNotVisible.bind(() => { this.scene.visible = false; });

            this.game.scale.set(0.016, 0.016, 0.016);
            this.game.rotateX(Math.PI / 2);
            this.game.rotateY(Math.PI / 2);

            this.trackerGroup.add(this.game);
            this.scene.add(this.trackerGroup);
        } else {
            this.scene.background = new THREE.Color(0x333333);

            this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight);
            this.camera.position.set(0, 1.5, 6.5);

            this.controls = new OrbitControls(this.camera, this.renderer.domElement);

            geometry = new THREE.BoxGeometry(134, 110, 191);

            const loader = new THREE.TextureLoader();

            var up = loader.load(box_up);
            var top = loader.load(box_top);
            var side = loader.load(box_side);
            var bottom = loader.load(box_bottom);

            var materials = [
                new THREE.MeshBasicMaterial({ map: side }),
                new THREE.MeshBasicMaterial({ map: side }),
                new THREE.MeshBasicMaterial({ map: top }),
                new THREE.MeshBasicMaterial({ map: bottom }),
                new THREE.MeshBasicMaterial({ map: up }),
                new THREE.MeshBasicMaterial({ map: up })
            ];

            this.box = new THREE.Mesh(geometry, materials);
            this.updateTextureEncoding(this.box);
            this.box.position.y = -56;

            this.game.position.y = -1;
            this.game.add(this.box);

            this.game.scale.set(0.025, 0.025, 0.025);

            this.scene.add(this.game);
        }
    }

    updateTextureEncoding(obj) {
        const encoding = THREE.sRGBEncoding;

        this.traverseMaterials(obj, (material) => {
            if (material.map) material.map.encoding = encoding;
            if (material.emissiveMap) material.emissiveMap.encoding = encoding;
            if (material.map || material.emissiveMap) material.needsUpdate = true;
        });
    }

    traverseMaterials(object, callback) {
        object.traverse((node) => {
            if (!node.isMesh) return;
            const materials = Array.isArray(node.material)
                ? node.material
                : [node.material];
            materials.forEach(callback);
        });
    }

    getCubeMapTexture(environment) {
        return new Promise((resolve, reject) => {
            new RGBELoader(this.manager)
                .setDataType(THREE.HalfFloatType)
                .load(environment, (texture) => {
                    const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
                    this.pmremGenerator.dispose();

                    resolve({ envMap });

                }, undefined, reject);

        });
    }

    loadModel(model, callback, manager) {
        const gltfLoader = new GLTFLoader(manager);

        gltfLoader.load(model, (gltf) => {
            if (callback) callback(gltf.scene);
        }, undefined, () => {
            console.log('An error ocurred loading the GLTF model');
        });
    }

    loadBoard() {
        this.loadModel(board, (object) => {
            this.board = object;
            this.loadLevel(1, this.loadFont);
        }, this.manager);
    }

    loadLevel(index, callback) {
        const model = this.levels[index - 1];

        this.level = index;
        this.loadModel(model, (object) => {
            this.model = object;
            this.updateMaterial(this.model);
            this.model.scale.set(100, 100, 100);
            if (callback) callback();
        });
    }

    loadFont() {
        const loader = new FontLoader();
        loader.load(fontType, this.onFontLoaded);
    }

    onFontLoaded(font) {
        this.font = font;
        if (this.props.onLoaded) {
            this.props.onLoaded();
        }
    }

    start() {
        if (this.floor) {
            this.game.remove(this.floor);
        }

        this.fadeIn(this.board, 1000, 0, () => {
            this.resetOpacity(this.board);
            this.showLevel(this.onReady, 1000);
        });
    }

    changeLevel(level) {
        if (level > this.levels.length) return;

        const onHidden = () => {
            this.loadLevel(level, () => {
                this.showLevel();
            });
        }
        if (this.model) {
            this.hideLevel(onHidden);
        } else onHidden();
    }

    hideLevel(callback) {
        new TWEEN.Tween(this.model.position)
            .to({ y: -60 }, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                this.game.remove(this.model);
                if (callback) callback();
            })
            .start();
    }

    showLevel(callback, delay) {
        this.model.position.y = -60;
        this.game.add(this.model);

        new TWEEN.Tween(this.model.position)
            .to({ y: 0 }, 1000)
            .delay(delay ? delay : 0)
            .easing(TWEEN.Easing.Quadratic.In)
            .onComplete(callback)
            .start();
    }

    onReady() {
        if (!this.props.onReady) return;
        setTimeout(this.props.onReady, 1000);
    }

    onMarker(found) {
        if (!this.props.onMarker) return;
        this.props.onMarker(found);
    }

    nextQuestion() {
        const questions = this.props.questions;
        const level = this.props.state.level;
        const index = this.props.state.index;
        const question = questions[level][index];

        this.changeText(question);
    }

    clear() {
        if (this.question) this.slideOut(this.question);
        if (this.answers) this.slideOut(this.answers, 500);
    }

    breakText(text) {
        return text.split('\n');
    }

    getLines(text, size) {
        const linesGroup = new THREE.Group();
        const strokeMat = new THREE.MeshBasicMaterial({ color: 'black' });
        const material = new THREE.MeshBasicMaterial({ color: 'white' });

        const lines = this.breakText(text);

        let geometry, stroke, mesh;

        for (var i = lines.length - 1; i >= 0; i--) {
            geometry = new TextGeometry(lines[i], {
                font: this.font,
                size,
                height: 0.01
            }).center();

            stroke = new THREE.Mesh(geometry, strokeMat);
            stroke.position.set(-0.5, (lines.length - i - 1) * 7.5, -0.5);

            mesh = new THREE.Mesh(geometry, material);
            mesh.position.set(0, (lines.length - i - 1) * 7.5, 0);

            linesGroup.add(stroke);
            linesGroup.add(mesh);
        }

        this.centerObject(linesGroup);

        return linesGroup;
    }

    changeText(data) {
        if (this.question) this.game.remove(this.question);

        this.question = this.getLines(data.question, 5);
        this.question.position.set(0, 80, -50);
        this.question.rotateX(-Math.PI / 16);

        this.slideIn(this.question, 0, () => {
            this.changeAnswers(data);
        });
    }

    changeAnswers(data) {
        const answers = data.answers;
        const geometry = new THREE.PlaneGeometry(50, 50);

        const materials = [answer_a, answer_b, answer_c, answer_d];

        let group, plane, map, material, posX = 0;

        if (this.answers) this.game.remove(this.answers);

        this.answers = new THREE.Group();

        for (var i = 0; i < answers.length; i++) {
            group = new THREE.Group();

            map = new THREE.TextureLoader().load(materials[i]); //TODO: PRELOAD
            material = new THREE.MeshBasicMaterial({ map, transparent: true });

            const answer = this.getLines(answers[i], 5);

            plane = new THREE.Mesh(geometry, material);

            group.add(answer);
            group.add(plane);

            group.position.set(posX, 0, 0);

            this.answers.add(group);

            posX += data.width * 50;
        }

        this.centerObject(this.answers);

        switch (this.level) {
            case 1:
                this.answers.position.y = 20;
                this.answers.position.z = 60;
                break;
            case 2:
                this.answers.position.y = 45;
                this.answers.position.z = 15;
                break;
            case 3:
                this.answers.position.y = 20;
                this.answers.position.z = 70;
                break;
        }

        this.answers.rotateX(-Math.PI / 16);

        this.slideIn(this.answers, 0, null);
    }

    slideIn(object, delay, callback) {
        const y = object.position.y;
        object.position.y = y + 5;

        new TWEEN.Tween(object.position)
            .to({ y }, 1000)
            .delay(delay ? delay : 0)
            .easing(TWEEN.Easing.Quadratic.In)
            .start();

        this.fadeIn(object, 500, delay, callback);
    }

    slideOut(object, delay, callback) {
        const y = object.position.y + 5;

        new TWEEN.Tween(object.position)
            .to({ y }, 1000)
            .delay(delay ? delay : 0)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();

        this.fadeOut(object, 500, delay, callback);
    }

    fadeIn(object, time, delay, callback) {
        this.updateOpacity(object, 0);
        this.game.add(object);

        let t = { opacity: 0 };

        new TWEEN.Tween(t)
            .to({ opacity: 1 }, time)
            .delay(delay ? delay : 0)
            .easing(TWEEN.Easing.Quadratic.In)
            .onUpdate(() => {
                this.updateOpacity(object, t.opacity);
            })
            .onComplete(() => {
                if (callback) callback();
            })
            .start();
    }

    fadeOut(object, time, delay, callback) {
        let t = { opacity: 1 };

        new TWEEN.Tween(t)
            .to({ opacity: 0 }, time)
            .delay(delay ? delay : 0)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
                this.updateOpacity(object, t.opacity);
            })
            .onComplete(() => {
                this.game.remove(object);
                if (callback) callback();
            })
            .start();
    }

    updateMaterial(model) {
        let oldMaterial;

        const updateRefraction = child => {
            const oldMaterial = child.material;
            child.material = new THREE.MeshPhysicalMaterial();
            child.material.normalMap = oldMaterial.normalMap;
            child.material.roughness = 0;
            child.material.thickness = 3;
            child.material.transmission = 1;
        }

        model.traverse(function (child) {
            if (child.isMesh) {
                switch (child.name) {
                    case 'glass1_glassShader_0':
                    case 'glass1_glassShader_0_1':
                    case 'beerGlassNew_glassShader_0':
                    case 'beerGlassNew_glassShader_0_1':
                    case 'beerGlassNew_glassShader_0001':
                        updateRefraction(child);
                        break;
                    case 'garrafa_glass_0001':
                    case 'garrafa_glass_0_1':
                    case 'garrafa_glass_0':
                        updateRefraction(child);
                        child.material.color = new THREE.Color(0x362419);
                        break;
                    case 'liquid1_beerShader_0':
                    case 'liquid1_beerShader_0_1':
                    case 'beerLiquid_beerShader_0':
                    case 'beerLiquid_beerShader_0_1':
                    case 'beerLiquid_beerShader_0001':
                    case 'liquido_beerShader_0001':
                    case 'liquido_beerShader_0_1':
                    case 'liquido_beerShader_0':
                        oldMaterial = child.material;
                        child.material = new THREE.MeshPhysicalMaterial();
                        child.material.map = oldMaterial.map;
                        break;
                }
            }
        });
    }

    updateOpacity(model, opacity) {
        model.traverse(function (child) {
            if (child.isMesh === true) {
                child.material.transparent = true;
                child.material.opacity = opacity;
            }
        });
    }

    resetOpacity(model) {
        model.traverse(function (child) {
            if (child.isMesh === true) {
                child.material.transparent = false;
                child.material.opacity = 1;
            }
        });
    }

    getSize(obj) {
        const box = new THREE.Box3().setFromObject(obj);
        return box.getSize(new THREE.Vector3());
    }

    centerObject(obj) {
        const box = new THREE.Box3().setFromObject(obj);
        const center = box.getCenter(new THREE.Vector3());

        obj.position.x += (obj.position.x - center.x);
        obj.position.y += (obj.position.y - center.y);
        obj.position.z += (obj.position.z - center.z);
    }

    update(time) {
        if (this.camera && this.renderer && this.scene) {
            if (this.camera.updateFrame) {
                this.camera.updateFrame(this.renderer);
            }
            if (this.controls) this.controls.update();
            this.renderer.render(this.scene, this.camera);
        }

        TWEEN.update(time);

        window.requestAnimationFrame(this.update);
    }

    render() {
        return (
            <Root ref={ref => (this.mount = ref)}></Root>
        );
    }
}

export default Game;
