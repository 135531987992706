import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../common/Button';
import api from '../../services';
import { useAuth } from '../../lib/auth';

const Root = styled.div`
	height: 100vh;
	min-height: 600px;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding: 0 25px;
	z-index: 2;
	width: 100%;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 320px;
	gap: 8px;
	width: 100%;
	padding-bottom: 20px;
`;

function Intro({ onStart }) {
	const { isAuthenticated, user } = useAuth();

	const onGameStart = async () => {
		const data = isAuthenticated ? { user: { connect: { id: user.id } } } : {}
		const result = await api.gamestarts.create(data);
		if (onStart) onStart(result);
	}

	return (
		<Root>
			<Content>
				<WrapperBtns>
					<Button
						type="red"
						text="Começar"
						onClick={onGameStart}
					/>
				</WrapperBtns>
			</Content>
		</Root>
	);
}

export default Intro;
