import styled from 'styled-components';
import Icon from './Icon';

const ButtonElement = styled.a`
	display: flex;
	margin: 0 auto;
	padding: ${(props) => (props.quiz ? '20px 36px' : '12px')};
	min-width: ${(props) => (props.icon ? '85px' : '120px')};
	width: 100%;
	background-color: ${(props) => backgroundColors[props.type]};
	border-radius: 10px;
	box-shadow: 0px 0.5px 1px 0px #fff inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
	color: ${(props) =>
		props.type === 'white'
			? props.theme.colors.black
			: props.theme.colors.white};
	cursor: pointer;
	font-size: ${(props) =>
		props.icon
			? props.quiz
				? '28px'
				: props.score
				? '28px'
				: '20px'
			: '28px'};
	font-weight: ${(props) => (props.quiz ? '700' : '500')};
	font-family: ${(props) => (props.quiz ? 'Roboto' : 'SB Pakenham')};
	justify-content: center;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: all 350ms ease-in-out;
	&:hover {
		background-color: ${(props) => hoverBgColors[props.type]};
		color: ${(props) =>
			props.type === 'white'
				? props.theme.colors.black
				: props.theme.colors.white};
	}
`;

const TextIcon = styled.div`
	position: relative;
	align-items: center;
	display: flex;
	gap: 4px;
`;

const backgroundColors = {
	gold: '#B7A66B',
	black: '#000000',
	red: '#D6001C',
	white: '#fff',
};

const hoverBgColors = {
	gold: '#000000',
	black: '#B7A66B',
	red: '#000000',
	white: '#fff',
};

function Button(props) {
	return (
		<ButtonElement {...props}>
			{props.icon ? (
				<TextIcon>
					<Icon
							icon={props.svg}
							size={19}
							color={props.type === 'white' ? 'black' : 'white'}
						/>
					<div>{props.text}</div>
				</TextIcon>
			) : (
				props.text
			)}
		</ButtonElement>
	);
}

export default Button;
