import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Timer from '../common/Timer';

import Button from '../common/Button';

const Root = styled.div`
	height: 100vh;
	min-height: 600px;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	transition: top .4s ease;
	flex-direction: column;
	margin: 0 auto;
	padding: 0 25px;
	transition: margin .4s ease;
	margin-bottom: ${(props) => props.show ? '0' : '-100px'};
	z-index: 2;
	width: 100%;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;
	padding-bottom: 20px;
`;

function Quiz({ state, questions, onAnswer }) {
	const [question, setQuestion] = useState(null);
	const [show, setShow] = useState(false);

	const [elapsed, setElapsed] = useState(0);

	const labels = [];

	labels.push({ name: 'A', color: 'red' });
	labels.push({ name: 'B', color: 'gold' });
	labels.push({ name: 'C', color: 'white' });
	labels.push({ name: 'D', color: 'black' });

	useEffect(() => {
		const level = state.level;
		const index = state.index;
		const question = questions && questions[level] ? questions[level][index] : null;
		
		if (question) {
			setQuestion(question);
			setShow(true);
		}

		const now = new Date().getTime();

		const interval = setInterval(() => {
			setElapsed(new Date().getTime() - now);
		}, 100);

		return () => clearInterval(interval);
	}, [state]);

	const checkAnswer = index => {
		const correct = index == question.correct;

		setShow(false);

		if (onAnswer) {
			onAnswer(index, correct, elapsed);
		}
	}

	return (
		<Root>
			<Timer time={state.time + elapsed} show={show} />
			<Content show={show}>
				{
					question && question.answers ?

						<WrapperBtns>
							{question.answers.map((item, index) => (
								<Button
									key={'answer-' + index}
									quiz={"true"}
									type={labels[index].color}
									text={labels[index].name}
									onClick={() => checkAnswer(index)}
								/>
							))}
						</WrapperBtns> : null
				}
			</Content>
		</Root>
	);
}

export default Quiz;
