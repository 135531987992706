import styled from 'styled-components';

const TxtElemen = styled.div`
	margin: 0 auto;
	color: ${(props) =>
		props.colorTxt ? props.colorTxt : props.theme.colors.white};
	font-weight: 500;
	font-size: ${(props) => (props.small ? '30px' : '40px')};
	text-align: center;
	max-width: ${(props) => (props.maxwidth ? props.maxwidth : '126px')};
	filter: ${(props) =>
		props.small ? 'none' : 'drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4))'};
`;

function Title(props) {
	return <TxtElemen {...props}>{props.title}</TxtElemen>;
}
export default Title;
