import styled from 'styled-components';

import Title from '../common/Title';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: ${(props) => props.theme.colors.white};
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 4vh;
	z-index: 2;
	margin: 0 auto;
	padding: 0 25px;
`;

function Rules({ onBack, onNext }) {
	const onNextButton = (e) => {
		e.preventDefault();
		if (onNext) onNext();
	};

	return (
		<Root>
			<Content>
				<Title title="Rules" txt="red" />
			</Content>
		</Root>
	);
}
export default Rules;
