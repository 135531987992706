import React, { createContext, useContext, useState } from "react";

import {
	gql,
	useQuery,
	useMutation,
	useApolloClient,
	useLazyQuery,
} from "@apollo/client";

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

const userFragment = `
	id
	name
	email
`;

const USER_QUERY = gql`
	query {
		authenticatedItem {
      		... on User {
			  ${userFragment}
    		}	
		}
	}
`;

const LOGIN_MUTATION = gql`
	mutation signin($email: String!, $password: String!) {
		authenticateUserWithPassword(email: $email, password: $password) {
			... on UserAuthenticationWithPasswordSuccess {
				item {
					id
					name
					email
				}
			}
			... on UserAuthenticationWithPasswordFailure {
				message
			}
		}
	}
`;

const LOGOUT_MUTATION = gql`
	mutation {
		endSession
	}
`;

const CREATE_MUTATION = gql`
	mutation create($data: UserCreateInput!) {
		createUser(data: $data) {
			${userFragment}
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: UserUpdateInput!) {
		updateUser(id: $id, data: $data) {
			${userFragment}
		}
	}
`;

export const AuthProvider = ({ children, initialUserValue }) => {
	const [user, setUser] = useState(initialUserValue);
	const [userLoading, setUserLoading] = useState(true);

	const client = useApolloClient();

	const { refetch } = useQuery(USER_QUERY, {
		fetchPolicy: "no-cache",
		onCompleted: ({ authenticatedItem, error }) => {
			if (error) {
				throw error;
			}

			setUser(authenticatedItem);
			setUserLoading(false);
		},
		onError: console.error,
	});

	const [getUser] = useLazyQuery(USER_QUERY, {
		onCompleted: ({ authenticatedItem }) => setUser(authenticatedItem),
	});

	const [signinMutation] = useMutation(LOGIN_MUTATION);

	const signin = (props) =>
		signinMutation({ variables: props })
			.then(
				async ({
					data: {
						authenticateUserWithPassword: { item, message } = {},
					},
				}) => {
					await client.resetStore();

					if (item) setUser(item);

					return { item, message };
				}
			)
			.catch((error) => {
				throw error;
			});

	const [signoutMutation] = useMutation(LOGOUT_MUTATION);

	const signout = (props) =>
		signoutMutation(props)
			.then(async ({ data: { endSession, errors } }) => {
				if (errors) {
					throw errors;
				}

				await client.resetStore();

				return { endSession };
			})
			.catch((error) => {
				throw error;
			});

	const [createMutation] = useMutation(CREATE_MUTATION);

	const register = (props) =>
		createMutation({ variables: { data: { ...props } } })
			.then(async ({ data: { createUser, errors } }) => {
				if (errors) {
					throw errors;
				}

				if (createUser) {
					await signin(props);
				}

				return { createUser };
			})
			.catch((error) => {
				throw error;
			});

	const [updateMutation] = useMutation(UPDATE_MUTATION);

	const update = (props) =>
		updateMutation({ variables: props })
			.then(async ({ data: { updateUser, errors } }) => {
				if (errors) {
					throw errors;
				}

				await client.resetStore();

				if (updateUser) {
					setUser(updateUser);
				}

				return { updateUser };
			})
			.catch((error) => {
				throw error;
			});

	return (
		<AuthContext.Provider
			value={{
				userLoading,
				isAuthenticated: !!user,
				signin,
				signout,
				getUser,
				register,
				update,
				user,
			}}>
			{!userLoading && children}
		</AuthContext.Provider>
	);
};
