import styled from 'styled-components';

import TextBox from '../common/TextBox';
import { useEffect } from 'react';

const Root = styled.div`
	height: 100vh;
	min-height: 600px;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-top: 120px;
	display: flex;
	gap: 5px;
`;

function Level({ state, onNext }) {

	useEffect(() => {
		const timeout = setTimeout(onNext, 2000);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<Root>
			<Content>
				<TextBox
					title={"Nível " + state.level}
					type="red"
				/>
				{
					state.level == 4 ?
						<TextBox
							title={"Última pergunta"}
							fontSize="20px"
							type="white"
						/>
						:
						<TextBox
							// title={`Pergunta ${(state.correct % 3) + 1} / 3`}
							title={`Pergunta ${state.index + 1}`}
							fontSize="20px"
							type="white"
						/>
				}

			</Content>
		</Root>
	);
}

export default Level;
