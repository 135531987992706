import styled from 'styled-components';

import Title from '../../components/common/Title';
import Button from '../../components/common/Button';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(images/bg/goldRed.png);
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 4vh;
	z-index: 2;
	margin: 0 auto;
	padding: 0 25px 50px;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding-bottom: 100px;
	width: 100%;
`;

const Terms = styled.div`
	color: ${(props) => props.theme.colors.white};
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;
	max-width: 200px;
	text-align: center;

	a {
		text-decoration-line: underline;
	}
`;

function AgeGate({ onNext }) {
	const onNextButton = (e) => {
		e.preventDefault();

		localStorage.setItem("legal_age", "true");

		window.location.reload();
	};

	return (
		<Root>
			<Content>
				<Title title="Tens mais de 18 anos?" />
				<WrapperBtns>
					<Button type="red" text="Não" />
					<Button
						type="red"
						text="Sim"
						href="#"
						onClick={onNextButton}
					/>
				</WrapperBtns>
				<Terms>
					Ao submeteres estes dados, estás a concordar com os{' '}
					<a
						href="https://www.superbock.pt/artigo/termos-e-condicoes-2/"
						passHref
					>
						Termos e Condições
					</a>{' '}
					e{' '}
					<a
						href="https://www.superbock.pt/artigo/politica-de-cookies/"
						passHref
					>
						Política de Cookies
					</a>{' '}
					do site Super Bock.
				</Terms>
			</Content>
		</Root>
	);
}
export default AgeGate;
