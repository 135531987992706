import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// import Splash from './components/sections/Splash';
import AgeGate from './components/sections/AgeGate';
import Home from './components/sections/Home';
import Help from './components/sections/Help';
import Prizes from './components/sections/Prizes';
import Rules from './components/sections/Rules';
import Form from './components/sections/Form';
import Marker from './components/sections/Marker';
import Ranking from './components/sections/Ranking';
import Quiz from './components/sections/Quiz';
import Intro from './components/sections/Intro';
import Flow from './components/sections/Flow';
import Demo from './components/sections/Demo';

import Header from './components/common/Header';

import Game from './components/zappar/Game';

import pool from './questions.json';
import Result from './components/sections/Result';
import Score from './components/sections/ScoreFun';
import Level from './components/sections/Level';

import api from './services';
import End from './components/sections/End';

const Root = styled.div`
    min-height
    height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #D6001C;
`;

const Content = styled.div`
    transition: opacity 0.6s ease-in-out;
    opacity: ${(props) => (props.fade)};
`;

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

function Router() {
    const [questions, setQuestions] = useState({});

    const [legal, setLegal] = useState(null);

    const [prevState, setPrev] = useState(null);
    const [nextState, setNext] = useState();
    const [fade, setFade] = useState(1);

    const [play, setPlay] = useState(1);

    const [state, setState] = useState({
        section: 'demo',
        started: false,
        granted: false,
        last: false,
        box: false,
        correct: 0,
        failed: 0,
        level: 1,
        index: 0,
        time: 0
    });

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    const handleBack = () => {
        switch (state.section) {
            case 'ranking':
            case 'prizes':
            case 'form':
                changeView(prevState.section, prevState);
                break;
            default: onPlayAgain(); //TODO
        }
    }

    const onBack = (e) => {
        e.preventDefault();
        handleBack();
    };

    const onStart = () => {
        changeView('flow', { granted: true });
    }

    const onFlow = box => {
        changeView('loading', { box });
    }

    const showIntro = () => {
        changeView('intro');
    }

    const onDemo = box => {
        setState({ ...state, granted: true });
        changeView('loading', { box });
    }

    const onPlayAgain = () => {
        changeView('demo', {
            ...state,
            started: false,
            granted: false,
            last: false,
            box: null,
            correct: 0,
            failed: 0,
            level: 1,
            index: 0,
            time: 0
        });
    }

    const shuffleQuestion = () => {
        let obj = {};
        for (var key in pool) {
            obj[key] = shuffleArray(pool[key]);
        }
        return obj;
    }

    const onLoaded = () => {
        changeView(state.box ? 'marker' : 'marker');
    }

    const startGame = (playId) => {
        changeView('start');
        setPlay(playId);
    }

    const resumeQuiz = () => {
        changeView('quiz');
    }

    const onReady = () => {
        setQuestions(shuffleQuestion());
        changeView('level', { started: new Date(), time: 0 });
    }

    const updateScore = async () => {
        // const score = await api.gamestarts.update(play, {
        //     correct: state.correct,
        //     failed: state.failed
        // });

        // console.log(score);
    }

    const onAnswer = (answer, correct, elapsed) => {
        let update = { ...state };
        let section = 'result';

        if (correct) {
            update.correct++;
            // update.level = Math.floor(update.correct / 3) + 1;
            update.level++;
            if (update.correct === 4) {
                section = 'score';
                updateScore();
            }
        } else {
            update.failed++;
            update.time += 10000;
            if (update.failed === 2) {
                section = 'score';
                updateScore();
            }
        }

        update.last = correct;
        update.time += elapsed;

        changeView(section, update);

        if (section === 'result') {
            setTimeout(() => {
                if (state.level !== update.level) {
                    update.index = 0;
                } else update.index++;

                changeView('clear', update);

                setTimeout(() => { changeView('level') }, 2000);
            }, 2000);
        }
    }

    const onMarker = found => {
        if (found && state.section == 'marker') {
            changeSection('intro');
        }
    }

    const changeSection = (section) => {
        setState({ ...state, section });
    }

    const changeView = (section, props) => {
        const newState = { ...props, section };
        setNext(newState); setPrev(state); setFade(0);
    }

    const onTransitionEnd = event => {
        if (event.propertyName != 'opacity') return;

        setState({ ...state, ...nextState });
        setFade(1);
    }

    useEffect(() => {
        const legal_age = localStorage.getItem("legal_age");
        setLegal(legal_age ? true : false);
    }, []);

    const renderContent = () => {
        if (legal) {
            switch (state.section) {
                case 'demo':
                    return <Demo onNext={onDemo} />
                case 'home':
                    return (
                        <Home
                            onNext={onStart}
                            goPrizes={() => changeView('prizes')}
                            goRanking={() => changeView('ranking')}
                            goHelp={() => changeView('help')}
                        />
                    );
                case 'flow':
                    return <Flow onNext={onFlow} />
                case 'marker':
                    return <Marker state={state} onNext={showIntro} />
                case 'intro':
                    return <Intro onStart={startGame} />
                case 'quiz':
                    return (
                        <Quiz
                            state={state}
                            questions={questions}
                            onAnswer={onAnswer}
                        />
                    );
                case 'result':
                    return (
                        <Result state={state} />
                    );
                case 'level':
                    return (
                        <Level state={state} onNext={resumeQuiz} />
                    );
                case 'score':
                    return <Score
                        state={state}
                        onNext={onPlayAgain}
                        goRegister={() => changeView('form')}
                        goRanking={() => changeView('ranking')}
                        goPrizes={() => changeView('prizes')} />;
                case 'form':
                    return <Form state={state} onNext={() => changeView('ranking')} />;
                case 'help':
                    return <Help onNext={() => changeView('home')} />;
                case 'ranking':
                    return <Ranking goPrizes={() => changeView('prizes')} />;
                case 'rules':
                    return <Rules />;
                case 'prizes':
                    return <Prizes />;
                case 'end':
                    return <End />;
            }
        } else if (legal != undefined) {
            return <AgeGate />;
        }
    };

    return (
        <Root>
            <Header onBack={state.section != 'home' && onBack} />
            {state.granted ? <Game questions={questions} state={state} onLoaded={onLoaded} onReady={onReady} onMarker={onMarker} /> : null}
            <Content fade={fade} onTransitionEnd={onTransitionEnd}>
                {renderContent()}
            </Content>
        </Root>
    );
}

export default Router;
