import styled from 'styled-components';
import ErrorMessage from './ErrorMessage';

const InputWrapper = styled.div`
	position: relative;
`;

const InputElement = styled.input`
	position: relative;
	padding: 17px 40px 17px 30px;
	width: 100%;
	appearance: none;
	border: 2px solid transparent;
	border-radius: 50px;
	background: rgba(217, 217, 217, 0.5);
	box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.2),
		0px -3px 1px 0px rgba(255, 255, 255, 0.2) inset;
	color: ${(props) => props.theme.colors.black};
	font-weight: normal;
	font-size: 18px;
	font-style: normal;

	option[disabled] {
		color: ${(props) => props.theme.colors.gray};
	}

	&:focus-visible {
		outline: none;
	}
`;

const Label = styled.label`
	color: ${(props) => props.theme.colors.black};
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 400;
	text-align: left;
`;

function Input(props) {
	return (
		<>
			{props.label ? <Label>{props.label}</Label> : null}
			<InputWrapper>
				<InputElement {...props} />
			</InputWrapper>
			<ErrorMessage {...props} />
		</>
	);
}

export default Input;
