import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

export const SHORT_PARTICIPATION_DATA = gql`
	fragment ShortParticipationData on Participation {
		id
	}
`;

export const PARTICIPATION_DATA = gql`
	fragment ParticipationData on Participation {
		id
		user {
			id
			name
		}
		failed
		time
	}
`;

const GET_PARTICIPATION = gql`
	query get($id: ID!) {
		participation(where: { id: $id }) {
			...ParticipationData
		}
	}
	${PARTICIPATION_DATA}
`;

const GET_BY_PERIOD = gql`
	query get($period: ID!) {
		participations(where: { period: { id: { equals: $period } } }) {
			...ParticipationData
		}
	}
	${PARTICIPATION_DATA}
`;

const UPDATE_PARTICIPATION = gql`
	mutation update($id: ID!, $data: ParticipationUpdateInput!) {
		updateParticipation(where: { id: $id }, data: $data) {
			...ShortParticipationData
		}
	}
	${SHORT_PARTICIPATION_DATA}
`;

const CREATE_MUTATION = gql`
	mutation create($data: ParticipationCreateInput!) {
		createParticipation(data: $data) {
			...ShortParticipationData
		}
	}
	${SHORT_PARTICIPATION_DATA}
`;

const apolloClient = initializeApollo();

export default {
	get: async (id) => {
		const {
			data: { participation },
		} = await apolloClient.query({
			query: GET_PARTICIPATION,
			variables: { id },
		});

		return participation;
	},

	getByPeriod: async (period) => {
		const {
			data: { participations },
		} = await apolloClient.query({
			query: GET_BY_PERIOD,
			variables: { period },
		});

		return participations;
	},
	
	update: async (id, data) => {
		const {
			data: { updatePartipation },
		} = await apolloClient.query({
			query: UPDATE_PARTICIPATION,
			variables: { id, data },
		});

		return { updatePartipation };
	},

	create: async data => {
		const {
			data: { createParticipation },
		} = await apolloClient.query({
			query: CREATE_MUTATION,
			variables: { data },
		});

		return { createParticipation };
	},
};
