import React from 'react';
import styled from 'styled-components';

import Title from '../common/Title';
import Button from '../common/Button';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(images/bg/goldRed.png);
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	gap: 4vh;
	margin: 0 auto;
	padding: 80px 25px 50px;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 15px;
	width: 100%;
`;

function Demo({ onNext }) {

	return (
		<Root>
			<Content>
				<Title
					title="Responde a todas as perguntas e mostra os teus conhecimentos cervejeiros"
					maxwidth="250px"
					small
				/>
				<WrapperBtns>
					<Button
						type="gold"
						text="Iniciar"
						onClick={() => onNext(false)}
					/>
				</WrapperBtns>
			</Content>
		</Root>
	);
}
export default Demo;
