import styled from 'styled-components';
import Button from '../common/Button';

const Root = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;
	padding: 190px 25px 0;
	max-width: 600px;
    margin: 0 auto;
	z-index: 2;
	width: 100%;
	gap: 10px;
`;

const Title = styled.h3`
	margin: 0;
	font-weight: 500;
	font-size: 30px;
	line-height: 26px;
	margin-bottom: 30px;
	color: ${(props) => props.theme.colors.primary};
`;

const Step = styled.div`
	margin-bottom: 30px;
`;

const Subtitle = styled.h4`
	margin: 0;
	font-weight: 500;
	font-size: 30px;
	line-height: 39px;
	color: ${(props) => props.theme.colors.black};
`;

const Paragraph = styled.p`
	font-weight: 300;
	font-size: 13px;
	line-height: 18px;
	font-family: Roboto;
	color: ${(props) => props.theme.colors.black};
`;

const Banner = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	box-sizing: border-box;
	padding: 40px;
	width: 100%;
`;

const CTA = styled.h5`
	color: ${(props) => props.theme.colors.white};
	font-weight: 500;
	font-size: 30px;
	line-height: 27px;
	margin-bottom: 20px;
	text-align: center;
	margin-top: 0;
`;

function Help({ onNext }) {
	return (
		<Root>
			<Content>
				<Title>
					Jogar o Saber Autêntico é tão simples e divertido quanto abrir uma Super Bock.
				</Title>
				<Step>
					<Subtitle>Passo 1</Subtitle>
					<Paragraph>Podes jogar com ou sem a caixa, mas a experiência é melhor quando tens uma caixa de Super Bock.</Paragraph>
					<Paragraph>Para isso, basta apontar o teu telemóvel para a parte de cima da caixa e seguir as instruções. Assim que o telemóvel reconhecer a caixa, o jogo começa. Em alternativa, podes apontar o teu telemóvel para uma superfície plana, preferencialmente uma mesa, assim que o telemóvel identifica a superfície, o jogo começa.</Paragraph>
				</Step>
				<Step>
					<Subtitle>Passo 2</Subtitle>
					<Paragraph>No jogo, serão apresentadas uma série de questões sobre o universo cervejeiro e sobre a Super Bock para testares os teus conhecimentos. Sempre que responderes uma questão de forma correta, avanças. Caso respondas de forma incorreta, será adicionado ao teu tempo 10 segundos. Se errares 3 respostas durante o jogo, tens de começar o jogo outra vez.</Paragraph>
					<Paragraph>A cada três respostas corretas, avanças um nível. O jogo é composto por 3 níveis com 3 perguntas por nível e 1 nível final com uma única pergunta. Para ter o teu tempo considerado em ranking é necessário responder corretamente 10 perguntas e preencher os teus dados ao final do jogo.</Paragraph>
				</Step>
				<Step>
					<Subtitle>Passo 3</Subtitle>
					<Paragraph>O objetivo do jogo é responder todas as perguntas no menor tempo possível. Os tempos serão classificados num ranking quinzenal, onde os primeiros 3 colocados por quinzena ganham prémios.</Paragraph>
				</Step>
				<Step>
					<Subtitle>Passo 4</Subtitle>
					<Paragraph>Jogar é mais divertido entre amigos.</Paragraph>
					<Paragraph>Partilha o Saber Autêntico com os teus amigos para ver quem é que sabe mais sobre a Super Bock.</Paragraph>
				</Step>
			</Content>
			<Banner>
				<CTA>Mostra o teu Saber Autêntico e faz um brinde ao conhecimento cervejeiro.</CTA>
				<Button
					type="red"
					text="Vamos Jogar?"
					onClick={onNext}
				/>
			</Banner>
		</Root>
	);
}
export default Help;
