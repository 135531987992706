import styled from 'styled-components';

const Root = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	display: flex;
	justify-content: center;
	color: ${(props) => props.theme.colors.white};
	text-align: center;
	font-family: 'Roboto';
	font-size: 25px;
	font-weight: 700;
	border-radius: 6px;
	padding: 30px 20px 10px;
	background: linear-gradient(
			149deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#d6001c;
	box-shadow: 0px 5.333333492279053px 8.296296119689941px 0px
			rgba(0, 0, 0, 0.4),
		1.185185194015503px 1.185185194015503px 1.185185194015503px 0px
			rgba(0, 0, 0, 0.25) inset,
		0px -1.7777777910232544px 0.5925925970077515px 0px rgba(
				255,
				255,
				255,
				0.2
			) inset;

	position: fixed;
	transition: top .4s ease;
	top: ${(props) => props.show ? '16vh' : '0vh'};
	z-index: 90;
`;

const Timer = ({ time, show }) => {
	function parseTime(millis) {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
	}

	return <Root show={show}>{parseTime(time)}</Root>;
};

export default Timer;
