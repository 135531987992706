import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

export const PERIOD_DATA = gql`
	fragment PeriodData on Period {
		id
	}
`;

const GET_PERIODS = gql`
	query get($date: DateTime!) {
		periods(where: { start: { lte: $date }, end: { gte: $date } }) {
			...PeriodData
		}
	}
	${PERIOD_DATA}
`;

const GET_LAST = gql`
	query get($date: DateTime!) {
		periods(where: { end: { lte: $date } }, orderBy: [{ end: desc }]) {
			...PeriodData
		}
	}
	${PERIOD_DATA}
`;

const apolloClient = initializeApollo();

export default {
	get: async date => {
		const {
			data: { periods },
		} = await apolloClient.query({
			query: GET_PERIODS,
			variables: { date },
		});

		return periods && periods.length ? periods[0] : null;
	},

	last: async date => {
		const {
			data: { periods },
		} = await apolloClient.query({
			query: GET_LAST,
			variables: { date },
		});

		return periods && periods.length ? periods[0] : null;
	},
};
