import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

export const GAMESTART_DATA = gql`
	fragment GameStartData on GameStart {
		id
	}
`;

const CREATE_MUTATION = gql`
	mutation create($data: GameStartCreateInput!) {
		createGameStart(data: $data) {
			...GameStartData
		}
	}
	${GAMESTART_DATA}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: GameStartUpdateInput!) {
		updateGameStart(where: { id: $id }, data: $data) {
			...GameStartData
		}
	}
	${GAMESTART_DATA}
`;

const apolloClient = initializeApollo();

export default {
	update: async (id, data) => {
		console.log(id);
		console.log(data);
		// const {
		// 	data: { updateGameStart },
		// } = await apolloClient.query({
		// 	query: UPDATE_MUTATION,
		// 	variables: { id, data },
		// });

		// return { updateGameStart };
	},

	create: async data => {
		const {
			data: { createGameStart },
		} = await apolloClient.query({
			query: CREATE_MUTATION,
			variables: { data },
		});

		return { createGameStart };
	},
};
