import styled from 'styled-components';

import Icon from './Icon';

const Navigator = styled.div`
	border-radius: 0px 0px 30px 30px;
	box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.4),
		0px 0.5px 1px 0px rgba(255, 255, 255, 0.9) inset;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	z-index: 99;
`;
const Top = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	display: flex;
	justify-content: center;
	// padding: 25px 0 15px 0;
	padding-top: 5px;
	width: 100%;
	position: relative;
	a {
		position: absolute;
		top: 50%;
		left: calc(0% + 20px);
		transform: translateY(-50%);
	}
`;

const Logo = styled.img`
	// max-width: 33vh;
	// width: 100%;
	height: 100px;
`;

const Bottom = styled.div`
	color: ${(props) => props.theme.colors.white};
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.55px;
	background: ${(props) => props.theme.colors.gold};
	width: 100%;
	padding: 8px 0;
`;

const Header = ({ onBack }) => {
	return (
		<Navigator>
			<Top>
				{onBack && (
					<a href="#" onClick={onBack}>
						<Icon icon="arrow-back" size={20} color="white" />
					</a>
				)}
				<Logo
					src={`images/logo/saborAutentico2.png`}
					alt="Super Bock"
				/>
			</Top>
			<Bottom>Sê responsável. Bebe com moderação.</Bottom>
		</Navigator>
	);
};

export default Header;
