import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
	display: flex;
	padding: 30px 16px;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${(props) => props.theme.colors.primary};
	border-radius: 10px;
	box-shadow: 0px 0.5px 1px 0px rgba(255, 255, 255, 0.9) inset,
		0px 9px 14px 0px rgba(0, 0, 0, 0.12);
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	gap: 14px;
	align-items: center;
`;

const Avatar = styled.div`
	position: relative;
	align-items: center;
	background-color: ${(props) => props.theme.colors.white};
	background-size: cover;
	background-position: center;
	border-radius: 50% !important;
	border: 2px solid ${(props) => props.theme.colors.white};
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	width: 43px;
	height: 43px;
`;

const Position = styled.div`
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 700;
	color: ${(props) => props.theme.colors.white};
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	width: 100%;
`;
const Name = styled.div`
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 700;
	line-height: 15px;
	color: ${(props) => props.theme.colors.white};
`;

const Title = styled.div`
	font-family: 'Roboto';
	font-size: 10px;
	font-weight: 300;
	line-height: 12px;
	color: ${(props) => props.theme.colors.gold};
`;

function CardPrize({ position, name, title, photo }) {
	return (
		<Root>
			<Wrapper>
				<Position>{position}º</Position>
				<Avatar
					style={{
						backgroundImage: `url(images/${photo}.png)`,
					}}
				/>
				<Column>
					<Name>{name}</Name>
					<Title>{title}</Title>
				</Column>
			</Wrapper>
		</Root>
	);
}

export default CardPrize;
