import styled from 'styled-components';

import Title from '../common/Title';
import CardPrize from '../common/CardPrize';

const Root = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: auto;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 160px 25px 0;
	max-width: 600px;
    margin: 0 auto;
	z-index: 2;
	width: 100%;
	gap: 10px;
`;

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;
	width: 100%;
`;

const Disclamer = styled.div`
	margin: 20px auto;
	max-width: 240px;
	text-align: center;
`;

const Paragraph = styled.p`
	font-weight: 300;
	font-size: 13px;
	line-height: 18px;
	font-family: Roboto;
	color: ${(props) => props.theme.colors.black};
`;

const Link = styled.a`
	font-weight: 700;
	color: #000;
`;

function Prizes() {
	return (
		<Root>
			<Content>
				<Title title="Prémios" colorTxt="1E1E1E" small />
				<CardWrapper>
					<CardPrize
						number="1"
						title="Super Bock Sky o ano todo"
						subtitle="(24 caixas, 2 caixas entregues por mês durante o ano de 2024)"
						photo="sb_sky"
					/>
					<CardPrize
						number="2"
						title="Pack Seleção 1927 + Arcádia"
						subtitle="(1 Garrafa Super Bock Selecção 1927 Munich Dunkel + 2 copos Selecção 1927 + Bombons Arcadia Chocolate de Leite e Malte)"
						photo="pack_1927"
					/>
					<CardPrize
						number="3"
						title="Caixa 6 Copos Tulipa Super Bock Original "
						photo="copo_tulipa"
					/>
				</CardWrapper>
				<Disclamer>
					<Paragraph>Imagens ilustrativas, para mais informações consulte o
						<Link href="/pdf/regulamento_19_11.pdf" target="_blank">
							{' '}regulamento
						</Link>.
					</Paragraph>
				</Disclamer>
			</Content>
		</Root>
	);
}
export default Prizes;
