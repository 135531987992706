import styled from 'styled-components';
import { useState, useEffect } from 'react';

import Title from '../common/Title';
import Icon from '../common/Icon';
import MyRankingCard from '../common/MyRankingCard';
import PersonRankingCard from '../common/PersonRankingCard';
import api from '../../services';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: ${(props) => props.theme.colors.white};
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: auto;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 160px 25px 0;
	max-width: 600px;
    margin: 0 auto;
	z-index: 2;
	width: 100%;
	gap: 10px;
`;

const Top = styled.div`
	position: relative;
	width: 100%;
	margin-bottom: 40px;
`;

const ButtonElement = styled.a`
	display: flex;
	margin: 0 auto;
	padding: 8px 20px;
	width: fit-content;
	background-color: ${(props) => props.theme.colors.primary};
	border-radius: 10px;
	box-shadow: 0px 0.5px 1px 0px #fff inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
	color: ${(props) => props.theme.colors.white};
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;
	text-decoration: none;
	transition: all 350ms ease-in-out;
	&:hover {
		background-color: ${(props) => props.theme.colors.black};
		color: ${(props) => props.theme.colors.white};
	}
	margin-top: -20px;
`;

const TextIcon = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const IconElement = styled.div`
	display: block;
	margin-right: 6px;
`;

const Photo = styled.div`
	height: 159px;
	background-color: ${(props) => props.theme.colors.black};
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 10px;
	box-shadow: 0px -3px 1px 0px rgba(255, 255, 255, 0.2) inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
	width: 100%;
	background-image: url(images/sb_sky.png);
`;

const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding: 6px 0px 30px;
`;

const OptionContainer = styled.div`
	display: flex;
	gap: 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);

	justify-content: space-between;
`;

const Option = styled.div`
	cursor: pointer;
	position: relative;
	padding-bottom: 10px;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
`;

const TabPanel = styled.div`
	width: 100%;
`;

const Tab = ({ tabId, tabName, activeTab, onSelectTab }) => {
	const isActive = activeTab === tabId;

	const handleClick = () => {
		onSelectTab(tabId);
	};
	return (
		<Option
			onClick={handleClick}
			style={{
				color: isActive ? '#1E1E1E' : 'rgba(0, 0, 0, 0.2)',
				borderBottom: isActive ? '4px solid #B7A66B' : 'none',
			}}>
			{tabName}
		</Option>
	);
};

function Ranking({ goPrizes }) {
	const [ranking, setRanking] = useState(null);
	const [activeTab, setActiveTab] = useState('current');

	const calendar = [
		{ id: 'current', name: 'Periodo atual' },
		{ id: 'last', name: 'Periodo anterior' },
	];

	const handleTabSelect = (tabId) => {
		setActiveTab(tabId);
	};

	const getTitle = failed => {
		switch (failed) {
			case 0:
				return 'Mestre Cervejeiro';
			case 1:
				return 'Cervejeiro Senior'
			default:
				return 'Cervejeiro Junior'
		}
	}

	const parseRanking = list => {
		let ranking = {}, arr = [];
		let item, user;

		for (var i = 0; i < list.length; i++) {
			item = list[i];
			if (item && item.user) {
				user = item.user;

				if (!ranking[item.user.id] || ranking[item.user.id].time > item.time) {
					ranking[item.user.id] = item;
				}
			}
		}

		for (var key in ranking) {
			arr.push(ranking[key]);
		}

		const sorted = arr.sort((a, b) => a.time - b.time);

		return sorted;
	}

	const getData = async () => {
		const now = new Date().toISOString();

		let period;

		setRanking(null);

		switch (activeTab) {
			case 'current':
				period = await api.periods.get(now);
				break;
			case 'last':
				period = await api.periods.last(now);
				break;
		}

		if (period && period.id) {
			const participations = await api.participations.getByPeriod(period.id);
			if (participations) setRanking(parseRanking(participations));
		}
	};

	useEffect(() => {
		getData();
	}, [activeTab]);
	
	return (
		<Root>
			<Content>
				<Top>
					<Photo />
					<ButtonElement onClick={goPrizes}>
						<TextIcon>
							<IconElement>
								<Icon icon="prize" size={19} color="white" />
							</IconElement>
							<div>Ver prémios</div>
						</TextIcon>
					</ButtonElement>
				</Top>
				<Title title="Ranking" colorTxt="#1E1E1E" small />

				<OptionContainer>
					{calendar.map((item, index) => (
						<Tab
							key={index}
							tabId={item.id}
							tabName={item.name}
							activeTab={activeTab}
							onSelectTab={handleTabSelect}
						/>
					))}
				</OptionContainer>
				{
					ranking ?
						<TabPanel>
							<CardWrapper>
								{
									ranking.length ?
										<MyRankingCard
											position="1"
											name={ranking[0].user.name}
											title={getTitle(ranking[0].failed)}
											photo="sb_sky"
										/> : null
								}
								{ranking.slice(1, ranking.length).map((item, index) => (
									<PersonRankingCard
										key={'ranking-' + index}
										position={index + 2}
										name={item.user.name}
										title={getTitle(item.failed)}
										photo={null}
									/>
								))}
							</CardWrapper>
						</TabPanel> : null
				}
			</Content>
		</Root>
	);
}
export default Ranking;
