import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '../../components/common/Button';
import Select from '../../components/common/Select';
import Input from '../common/Input';
import Checkbox from '../common/CheckBox';
// import DatePicker from 'react-datepicker';

// import 'react-datepicker/dist/react-datepicker.css';
import ErrorMessage from '../common/ErrorMessage';
import { useAuth } from '../../lib/auth';

import districts from './../../districts.json';
import api from '../../services';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: ${(props) => props.theme.colors.white};
	position: relative;
	min-height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
	// display: flex;
	// align-items: center;
	// justify-content: flex-end;
	// flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 60px;
	z-index: 2;
	margin: 0 auto;
	padding: 160px 25px 0;
`;

const Section = styled.section`
	align-items: flex-start;
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-around;
	padding: 40px 0;
	text-align: center;
	gap: 2vh;
`;

const Question = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`;

const Title = styled.div`
	color: ${(props) => props.theme.colors.primary};
	font-size: 50px;
	font-weight: 500;
	line-height: 21px;
`;

const Subtitle = styled.div`
	color: rgba(30, 30, 30, 0.55);
	font-family: Roboto;
	font-size: 13px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	filter: ${(props) =>
		props.small ? 'none' : 'drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4))'};
`;

const Privacy = styled.div`
	color: ${(props) => props.theme.colors.black};
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 300;
	line-height: 20px;
	text-align: center;
	margin: 0 auto;
	a {
		color: ${(props) => props.theme.colors.primary};
		font-weight: 700;
		line-height: normal;
		text-decoration-line: underline;
	}
`;

const WrapperCheckbox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-bottom: 10px;
`;

const Label = styled.label`
	color: ${(props) => props.theme.colors.black};
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 400;
	text-align: left;
`;

const LabelCheckbox = styled.label`
	color: ${(props) => props.theme.colors.black};
	display: block;
	padding: 0 0 0 30px;
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 300;
	letter-spacing: -0.39px;
	position: absolute;
	text-align: left;
	width: 80%;
	a {
		color: ${(props) => props.theme.colors.primary};
		font-weight: 700;
		line-height: normal;
		text-decoration-line: underline;
	}
`;

function Form({ state, onNext }) {
	const { register, signin } = useAuth();

	const [data, setData] = useState({
		name: '',
		email: null,
		birthdate: null,
		district: null,
		privacy: false,
		marketing: false
	});

	const [errors, setErrors] = useState({});

	const onChange = (e) => {
		if (e.target.type === 'checkbox')
			setData({ ...data, [e.target.name]: e.target.checked });
		else if (e.target.name == 'email') {
			setData({ ...data, [e.target.name]: e.target.value.toLowerCase() });
		} else setData({ ...data, [e.target.name]: e.target.value });
	};

	// const onChangeDate = (date) => {
	// 	setData({ ...data, birthdate: date });
	// };

	const validate = () => {
		let valid = true, errors = {};
		let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (data.name.length < 3) {
			errors.name = "Nome é obrigatório (mínimo 3 caracteres)"
			valid = false;
		}
		if (!regEmail.test(data.email)) {
			errors.email = "Email é inválido"
			valid = false;
		}
		if (!data.birthdate) {
			errors.birthdate = "Data de nascimento é obrigatória"
			valid = false;
		}
		if (!data.district) {
			errors.district = "Distrito é obrigatório"
			valid = false;
		}
		if (!data.privacy) {
			errors.privacy = "É obrigatória a aceitação das políticas"
			valid = false;
		}

		setErrors(errors);

		return valid;
	};

	const sendSuperBock = (email, consentTypeId) => {
        var now = new Date();
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.status === 200) {
                //myObj = JSON.parse(this.responseText);
                console.log('this.responseText', this.responseText)
            }
        };
        xhr.open("POST", "https://api.superbockgroup.com:7575/api/consent_manager/operations/update_consent", true);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader( 'Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOlsiQ3VzdG9tZXJzRGF0YVJlYWRlciIsIkZ1bmN0aW9uTG9jRGF0YVJlYWRlciIsIk1hdGVyaWFsc0RhdGFSZWFkZXIiLCJTYWxlc0RhdGFSZWFkZXIiLCJDb25maWd1cmF0aW9uc0RhdGFSZWFkZXIiLCJOZXdNYWludGVuYW5jZU9yZGVyUmVxdWVzdENvbXBsZXRlZEV2ZW50UHJvZHVjZXIiLCJOZXdPcmRlclJlcXVlc3RDb21wbGV0ZWRFdmVudFByb2R1Y2VyIiwiTmV3TWFpbnRlbmFuY2VPcmRlclJlcXVlc3RPcGVyYXRpb25FeGVjdXRvciIsIk5ld09yZGVyUmVxdWVzdE9wZXJhdGlvbkV4ZWN1dG9yIiwiQ29uc2VudE1hbmFnZXJVc2VyIl0sImV4cCI6MTY4NzgwMDMzOSwiaXNzIjoic3VwZXJib2NrZ3JvdXAuY29tIiwiYXVkIjoic3VwZXJib2NrZ3JvdXAuY29tIn0.vgcDdmgtogOgBA70BkMv59Pv4KT6FYKup6YPbCSAqdM');
        var data = JSON.stringify({
            "ConsentTypeId": 7,
            "ConsentValueId": 2,
            "CustomerCode": email,
            "Origin": 'Carlsberg / Externo',
            "StartingFrom": now.toLocaleDateString('pt-PT'),
            "Status": 1,
            "Process": 'probablybetternow',
            "Username": 'figma'
        });
        xhr.send(data);
    }

	const onSubmit = async (e) => {
		e.preventDefault();

		const isValid = validate();

		if (isValid) {
			try {
				const user = await api.users.get(data.email);

				if (!user) {
					const { createUser } = await register({
						name: data.name,
						email: data.email,
						password: 'Autentico!',
						district: data.district,
						birthday: new Date(data.birthdate).toISOString(),
						marketing: data.marketing
					});

					user = createUser;
				}

				if (user) {
					const { item, message } = await signin({
						email: data.email,
						password: 'Autentico!'
					});

					console.log('aqui')

					const participation = await api.participations.create({
						user: { connect: { id: user.id } },
						started: state.started.toISOString(),
						failed: state.failed,
						time: state.time
					});

					if (participation) onNext();
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	return (
		<Root>
			<Content>
				<Section>
					<Title>Olá</Title>
					<Subtitle>
						Cria uma conta para registar o teu ranking e habilita-te
						a ganhar super prémios.
					</Subtitle>
					<Column>
						<Question>
							<Input
								name="name"
								type="text"
								label="Nome"
								value={data.name}
								message={errors.name}
								invalid={errors.name}
								onChange={onChange}
								placeholder="Nome"
							></Input>
						</Question>
						<Question>
							<Input
								name="email"
								type="text"
								label="E-mail"
								value={data.email}
								message={errors.email}
								invalid={errors.email}
								onChange={onChange}
								placeholder="Email"
							></Input>
						</Question>
						<Question>
							<Input
								name="birthdate"
								type="date"
								label="Data de nascimento"
								value={data.birthdate}
								message={errors.birthdate}
								invalid={errors.birthdate}
								onChange={onChange}
								placeholder="dd-MM-yyyy"
							></Input>
							{/* <Label>Data de nascimento</Label> */}
							{/* <DatePicker selected={data.birthdate} onChange={(date) => onChangeDate(date)} dateFormat='dd-MM-yyyy' /> */}
							{/* <ErrorMessage message={errors.birthdate} invalid={errors.birthdate} /> */}
						</Question>
						<Question>
							<Label>Distrito</Label>
							<Select name="district" onChange={onChange}>
								<option value=""></option>
								{districts.map((item, index) => (
									<option key={item} value={item}>{item}</option>
								))}
							</Select>
							<ErrorMessage message={errors.district} invalid={errors.district} />
						</Question>
					</Column>
					<WrapperCheckbox>
						<Checkbox
							name="privacy"
							id="privacy"
							type="checkbox"
							onChange={onChange}
							checked={data.privacy}
						/>
						<LabelCheckbox htmlFor="privacy">
							Concordo com a{' '}
							<a href="#linkTo" passHref>
								politica de privacidade
							</a>
							{' '}e{' '}
							<a href="#linkTo" passHref>
								termos e condições
							</a>
							{' '}deste passatempo.
						</LabelCheckbox>
					</WrapperCheckbox>
					<ErrorMessage message={errors.privacy} invalid={errors.birthdate} />
					<WrapperCheckbox>
						<Checkbox
							name="marketing"
							id="marketing"
							type="checkbox"
							onChange={onChange}
							checked={data.marketing}
						/>
						<LabelCheckbox htmlFor="marketing">
							Aceito comunicações de futuras iniciativas
							semelhantes a esta por parte da Super Bock.
						</LabelCheckbox>
					</WrapperCheckbox>

					<Button
						type="red"
						text="Registar resultado"
						onClick={onSubmit}
					/>
					{/* <Privacy>
						Já tens uma conta? Faz{' '}
						<a href="#linkTo" passHref>
							sign-in
						</a>
					</Privacy> */}
				</Section>
			</Content>
		</Root>
	);
}

export default Form;
