import React, { useEffect } from 'react';
import styled from 'styled-components';

import Title from '../common/Title';

const Root = styled.div`
	background-color: rgba(0,0,0,.7);
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
	margin: 0 auto;
	padding: 0px 25px 60px;
	z-index: 2;
	width: 100%;
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 400px;
`;

const Logo = styled.img`
	width: 100%;
	padding: 30px 65px 0;
`;

function Marker({ state, onNext }) {

	useEffect(() => {
		if (state.box) return;
		const timeout = setTimeout(onNext, 3000);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<Root>
			{state.box ? (
				<Content>
					<Title
						title="Aponta para a caixa para conseguires começar o jogo"
						small
						maxwidth="261px"
					/>
					<Logo src={`images/detect_box.png`} alt="Super Bock" />
				</Content>
			) : (
				<Content style={{ gap: 10 }}>
					<Title
						// title="Coloca o jogo numa superfície horizontal para começar"
						title="Ajusta a posição e zoom antes de começar a jogar"
						small
						maxwidth="261px"
					/>
					<Logo src={`images/detect_phone.png`} alt="Super Bock" />
				</Content>
			)}
		</Root>
	);
}

export default Marker;
