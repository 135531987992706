import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
	display: flex;
	padding: 40px 16px;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${(props) => props.theme.colors.black};
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 10px;
	box-shadow: 0px -3px 1px 0px rgba(255, 255, 255, 0.2) inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	gap: 14px;
	align-items: center;
`;

const Circle = styled.div``;

const Position = styled.div`
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.primary};
	width: 43px;
	height: 43px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Title = styled.div`
	font-size: 30px;
	font-weight: 500;
	text-align: left;
	max-width: 160px;
	color: ${(props) => props.theme.colors.white};
`;

const Subtitle = styled.div`
	font-size: 20px;
`;

function CardPrize({ number, title, subtitle, photo }) {
	return (
		<Root
			style={{
				backgroundImage: `url(images/prizes/${photo}.png)`,
			}}
		>
			<Wrapper>
				<Circle>
					<Position>{number}º</Position>
				</Circle>
				<Title>{title} {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}</Title>
			</Wrapper>
		</Root>
	);
}

export default CardPrize;
