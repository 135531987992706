import React, { useState } from 'react';
import styled from 'styled-components';

import Title from '../common/Title';
import Button from '../common/Button';

const Root = styled.div`
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(images/bg/goldRed.png);
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 120px;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 2;
	gap: 4vh;
	margin: 0 auto;
	padding: 0 25px 50px;
`;

const Logo = styled.img`
	max-width: 21vh;
	width: 100%;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	width: 100%;
`;

const Link = styled.a`
	color: #fff;
`;

function Home({ onNext, goPrizes, goRanking, goHelp }) {

	return (
		<Root>
			<Content>
				{/* <Logo src={`images/logo/sb.png`} alt="Super Bock" /> */}
				<Title
					title="Mostra os teus conhecimentos cervejeiros e habilita-te a ganhar prémios."
					small
					maxwidth="260px"
				/>
				<Button
					type="red"
					text="Começar"
					onClick={onNext}
				/>
				<WrapperBtns>
					<Button
						icon
						svg="prize"
						type="gold"
						text="Prémios"
						onClick={goPrizes}
					/>
					<Button
						icon
						svg="ranking"
						type="gold"
						text="Ranking"
						onClick={goRanking}
					/>
					<Button
						icon
						svg="help"
						type="gold"
						text="Ajuda"
						onClick={goHelp}
					/>
				</WrapperBtns>
				<Link href="/pdf/regulamento_19_11.pdf" target="_blank">
					Regulamento
				</Link>
			</Content>
		</Root>
	);
}
export default Home;
