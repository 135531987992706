import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from './lib/apolloClient';
import { AuthProvider } from './lib/auth';

import Router from './router';

const theme = {
  breakpoints: {
    lg: '1024px',
  },
  colors: {
    primary: '#D6001C',
    black: '#1E1E1E',
    red_darker: '#AB0016',
    gold: '#A79866',
    white: '#fff',
  },
};

const App = () => {
  const apolloClient = useApollo();

  return (
    <Fragment>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>
  )
};

export default App;
