import styled from 'styled-components';

import Icon from './Icon';

const SelectWrapper = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%) rotate(-90deg);
	}
`;

const SelectElement = styled.select`
	position: relative;
	padding: 17px 40px 17px 30px;
	width: 100%;
	appearance: none;
	border: 2px solid transparent;
	border-radius: 50px;
	background: rgba(217, 217, 217, 0.5);
	box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.2),
		0px -3px 1px 0px rgba(255, 255, 255, 0.2) inset;
	color: ${(props) => props.theme.colors.black};
	font-weight: normal;
	font-size: 18px;
	font-style: normal;

	option[disabled] {
		color: ${(props) => props.theme.colors.gray};
	}

	&:focus-visible {
		outline: none;
	}
`;

function Select(props) {
	return (
		<SelectWrapper>
			<SelectElement {...props} />
			<Icon icon="arrow-back" size={20} color="#000000" />
		</SelectWrapper>
	);
}

export default Select;
