import React from 'react';
import styled, { ThemeContext } from 'styled-components';

const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const Icon = styled.svg`
	fill: none;
	stroke: ${(props) => props.theme.colors.white};
	stroke-width: 4px;
	padding: 1px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	-webkit-appearance: none;
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	display: inline-block;
	width: 16px;
	height: 16px;
	background: ${(props) =>
		props.checked ? props.theme.colors.primary : 'transparent'};
	border-radius: 16px;
	transition: all 150ms;
	box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary};
	${HiddenCheckbox}:focus + & {
		box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary};
	}
	${Icon} {
		visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
	}
`;

const Checkbox = ({ checked, ...props }) => (
	<CheckboxContainer>
		<HiddenCheckbox checked={checked} {...props} />
		<StyledCheckbox checked={checked}>
			<Icon viewBox="0 0 24 24">
				<polyline points="20 6 9 17 4 12" />
			</Icon>
		</StyledCheckbox>
	</CheckboxContainer>
);

export default Checkbox;
