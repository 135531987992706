import participations from './participations';
import gamestarts from './gamestarts';
import periods from './periods';
import users from './users';

export const api = {
	participations: { ...participations },
	gamestarts: { ...gamestarts },
	periods: { ...periods },
	users: { ...users },
};

export default api;
