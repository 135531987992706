import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

export const USER_DATA = gql`
	fragment UserData on User {
		id
	}
`;

const GET_USERS = gql`
	query get($email: String!) {
		users(where: { email: { equals: $email } }) {
			...UserData
		}
	}
	${USER_DATA}
`;

const apolloClient = initializeApollo();

export default {
	get: async email => {
		const {
			data: { users },
		} = await apolloClient.query({
			query: GET_USERS,
			variables: { email },
		});

		return users && users.length ? users[0] : null;
	},
};
