import styled from 'styled-components';

const backgroundColors = {
	gold: '#B7A66B',
	black: '#000000',
	red: '#D6001C',
	white: '#fff',
};

const TxtElemen = styled.div`
	margin: 0 auto;
	color: ${(props) =>
		props.colorTxt ? props.colorTxt : props.theme.colors.white};
	font-weight: 500;
	font-size: ${(props) => (props.fontSize ? props.fontSize : '30px')};
	text-align: center;
	padding: 10px 20px;
	background-color: ${(props) => backgroundColors[props.type]};
	box-shadow: 0px 0.5px 1px 0px #fff inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
	text-transform: uppercase;
	letter-spacing: 0.1em;
    border-radius: 40px;
	color: ${(props) =>
		props.type === 'white'
			? props.theme.colors.black
			: props.theme.colors.white};
`;

function TextBox(props) {
	return <TxtElemen {...props}>{props.title}</TxtElemen>;
}
export default TextBox;
