import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Title from '../common/Title';

import Button from '../common/Button';
import { useAuth } from '../../lib/auth';
import api from '../../services';

const Root = styled.div`
	background-color: rgba(0,0,0,.7);
	position: relative;
	height: 100vh;
    min-height: 600px;
    height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;
	padding: 120px 25px 15%;
	z-index: 2;
	width: 100%;
`;

const WrapperBtns = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 320px;
	gap: 8px;
	width: 100%;
	padding-bottom: 10px;
`;

const Time = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 6px;
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 700;
	text-align: center;

	padding: 20px 20px 10px;
	background: linear-gradient(
			149deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#d6001c;
	box-shadow: 0px 5.333333492279053px 8.296296119689941px 0px
			rgba(0, 0, 0, 0.4),
		1.185185194015503px 1.185185194015503px 1.185185194015503px 0px
			rgba(0, 0, 0, 0.25) inset,
		0px -1.7777777910232544px 0.5925925970077515px 0px rgba(
				255,
				255,
				255,
				0.2
			) inset;

	span {
		font-size: 42px;
		line-height: 49px;
	}
`;

const WrapperInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 100%;
	padding: 30px 0px 45px;
`;

const SubTitle = styled.div`
	color: ${(props) => props.theme.colors.white};
	font-family: 'Roboto';
	font-size: 13px;
	font-weight: 700;
	text-align: center;
	max-width: 200px;
`;

function Score({ state, onNext }) {
	function parseTime(millis) {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
	}

	const getTitle = () => {
		switch (state.failed) {
			case 0:
				return 'Mestre Cervejeiro';
			case 1:
				return 'Cervejeiro Senior'
			default:
				return 'Cervejeiro Junior'
		}
	}

	return (
		<Root>
			<Content>
				{
					state.last ?
						<>
							<Time>O teu tempo<br /><span>{parseTime(state.time)}</span></Time>
							<WrapperInfo>
								<Title
									title={"Parabéns, mostraste ser um " + getTitle()}
									small
									maxwidth="200px"
								/>
							</WrapperInfo>
						</>
						:
						<>
							<WrapperInfo>
								<Title
									title="Infelizmente não conseguiste terminar"
									small
									maxwidth="200px"
								/>
								<SubTitle>Achas que consegues melhor? Tenta outra vez.</SubTitle>
							</WrapperInfo>
						</>
				}
				<WrapperBtns>
					<Button
						type="red"
						text="Jogar novamente"
						onClick={onNext}
					/>
				</WrapperBtns>
			</Content>
		</Root>
	);
}

export default Score;
