import styled from 'styled-components';

import TextBox from '../common/TextBox';

const backgroundColors = {
	gold: '#B7A66B',
	black: '#000000',
	red: '#D6001C',
	white: '#fff',
};

const Root = styled.div`
	height: 100vh;
	min-height: 600px;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
`;

const Content = styled.div`
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-top: 120px;
	display: flex;
	gap: 5px;
`;

const Icon = styled.img`
	// width: 36px;
	// height: 36px;
	padding: 6px;
	margin-bottom: 5px;
	border-radius: ${(props) => props.circle ? '50%' : '10px'};
	background-color: ${(props) => backgroundColors[props.type]};
	box-shadow: 0px 0.5px 1px 0px #fff inset;
	filter: drop-shadow(0px 9px 14px rgba(0, 0, 0, 0.4));
`;

function Result({ state }) {
	return (
		<Root>
			<Content>
				{
					state.last ?
						<>
							<Icon src={`images/check.svg`} alt="Correct" type="gold" circle={true} />
							<TextBox
								title={`Resposta correta.`}
								fontSize="20px"
								type="gold"
							/>
						</>
						:
						<>
							<Icon src={`images/cross.svg`} alt="Correct" type="red" />
							<TextBox
								title={`Resposta incorrecta.`}
								fontSize="20px"
								type="red"
							/>
							<TextBox
								title={`Tens mais ${2 - state.failed} tentativa(s).`}
								fontSize="15px"
								type="white"
							/>
						</>
				}
			</Content>
		</Root>
	);
}

export default Result;
