import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

let apolloClient = null;

function createApolloClient(req) {
	const inBrowser = typeof window !== "undefined";
	return new ApolloClient({
		ssrMode: !inBrowser,
		connectToDevTools: inBrowser,
		link: createUploadLink({
			uri: `${
				process.env.NEXT_PUBLIC_SERVER_URL || "https://saber-autentico-backoffice-210a09d6761c.herokuapp.com"
			}/api/graphql`,
			credentials: "include",
			headers: {
				"Apollo-Require-Preflight": "true",
			},
		}),
		cache: new InMemoryCache(),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
			},
			query: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

export default function initializeApollo(initialState = null, req) {
	const _apolloClient = apolloClient ?? createApolloClient(req);

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		_apolloClient.cache.restore(initialState);
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient;
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;

	return _apolloClient;
}
